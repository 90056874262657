<!--
  Component informing the user that input is not allowed
-->
<template>
  <CContainer fluid class="login-common password-recovery text-center">
    <CRow class="header">
      <img src="~@/assets/img/logo-nissan-white.png" alt="logo-nissan" />
    </CRow>
    <CRow align-horizontal="center">
      <img src="~@/assets/img/logo-login.png" class="logo" />
    </CRow>
    <CRow align-horizontal="center">
      <CCol
        sm="6"
        md="5"
        lg="4"
        xxl="3"
        class="maintenance-msg--container fadeIn anim-speed--1s"
      >
        <p class="maintenance-msg--text">
          Estamos realizando tareas de mantenimiento.<br />
          En breve la aplicación volverá a estar disponible.<br />
          Disculpa las molestias.
        </p>
        <p class="maintenance-msg--text">
          Atualmente estamos a realizar trabalhos de manutenção.<br />
          Em breve a aplicação estará novamente disponível.<br />
          Desculpe o incómodo
        </p>

        <CButton color="primary" to="/" block> VOLVER / VOLTAR</CButton>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
export default {
  name: "NotAllowed",
};
</script>

<style lang="scss" scoped>
.maintenance-msg {
  &--container {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 1.3rem 2.3rem 0 2.3rem;
  }
  &--text {
    color: #fff;
    text-align: left;
  }
}
</style>
